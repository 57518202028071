import { ThemeSelectorFooter } from '@/components/ThemeSelector/ThemeSelectorFooter';
import { NavigationList } from '@/cutils/page-context/navigation';

import { BRLinks } from '../BRLinks';
import { rubrikenLinks, mediaLinks } from '../Footer.utils';
import { ScrollToTop } from '../ScrollToTop';
import { AppSection } from '../Section/AppSection';
import { LinkSection } from '../Section/LinkSection';
import { NewsletterSection } from '../Section/NewsletterSection';
import { Section } from '../Section/Section';
import { Social } from '../Social';

import styles from './FooterMobile.module.scss';

type Props = {
	className?: string;
	bayernNavigation: NavigationList;
	renderChildren: boolean;
};

export function FooterMobile({ bayernNavigation, className = '', renderChildren }: Props) {
	const isDesktop = false;

	return (
		<footer className={`${className} ${styles.footer}`} data-t-element="footer_navigation">
			{renderChildren ? (
				<>
					<ScrollToTop className={styles.scrollTop} />
					<Social />
					<LinkSection title="Bayern" links={bayernNavigation} expandable={!isDesktop} />
					<LinkSection title="Rubriken" links={rubrikenLinks} expandable={!isDesktop} />
					<LinkSection title="Medien" links={mediaLinks} expandable={!isDesktop} />
					<NewsletterSection className={styles.newsletterSection} />
					<AppSection />
					<Section title="Farbschema" expandable={false}>
						<ThemeSelectorFooter forceTheme="dark" />
					</Section>
					<BRLinks />
				</>
			) : null}
		</footer>
	);
}
