import FocusTrap from 'focus-trap-react';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { useImperativeDisableScroll } from '@/components/Header/use-imperative-disable-scroll';
import { IconSystem } from '@/icons/IconSystem';

import styles from './Dialog.module.scss';

type Props = PropsWithChildren<{
	show: boolean;
	onClose?: () => void;
	showClose?: boolean;
	footer?: ReactNode;
}>;

export function Dialog({ show, onClose, showClose = true, children, footer }: Props) {
	const focusTrapOptions = useMemo(() => {
		return {
			allowOutsideClick: false,
			// do not set the focus on activation
			initialFocus: false,
			escapeDeactivates: false,
		} satisfies FocusTrap.Props['focusTrapOptions'];
	}, []);

	// disable scrolling on main body if dialog is open
	useImperativeDisableScroll(typeof window !== 'undefined' ? document.body : undefined, show);

	if (typeof window === 'undefined') {
		return null;
	}

	return (
		show &&
		createPortal(
			<FocusTrap active={show} focusTrapOptions={focusTrapOptions}>
				<div className={styles.backdrop}>
					<div className={styles.modal}>
						{showClose ? (
							<header className={styles.header}>
								<button className={styles.closeButton} onClick={onClose}>
									schließen <IconSystem icon="close" variant="filled" />
								</button>
							</header>
						) : null}
						<section className={styles.content}>{children}</section>
						{footer ? <footer className={styles.footer}>{footer}</footer> : null}
					</div>
				</div>
			</FocusTrap>,
			document.body
		)
	);
}
