import { useLocalStorageValue } from '@react-hookz/web';
import { useIsOpen, useIsInitialized } from '@s-group/react-usercentrics';
import { useTheme } from 'next-themes';
import { useCallback, useState } from 'react';

import NoSSR from '@/cutils/misc/NoSSR';

import { Dialog } from '../base/Dialog/Dialog';
import { TextButton } from '../base/TextButton';

import styles from './RedesignModal.module.scss';
import { RedesignModalContent } from './RedesignModalContent';

function RedesignModalComponent() {
	const { setTheme, theme } = useTheme();
	const { value: welcomeDialogDismissed, set: setWelcomeDialogDismissed } = useLocalStorageValue('welcome-dialog-dismissed', {
		defaultValue: false,
	});
	const isUsercentricsOpen = useIsOpen();
	const isUsercentricsInitialized = useIsInitialized();

	const isUserCentricsDisplayed = !isUsercentricsInitialized || isUsercentricsOpen;

	const [showDialog, setShowDialog] = useState(!welcomeDialogDismissed);

	const saveThemeSettings = useCallback(() => {
		setWelcomeDialogDismissed(true);
		setShowDialog(false);
	}, [setWelcomeDialogDismissed]);

	return (
		<Dialog
			show={showDialog && !isUserCentricsDisplayed}
			showClose={false}
			footer={
				<TextButton className={styles.saveButton} display="highlight" onClick={() => saveThemeSettings()}>
					speichern
				</TextButton>
			}
		>
			<RedesignModalContent theme={theme} onThemeChange={setTheme} />
		</Dialog>
	);
}

export function RedesignModal() {
	return (
		<NoSSR>
			<RedesignModalComponent />
		</NoSSR>
	);
}
