/* eslint-disable @typescript-eslint/unified-signatures */
import { ComponentPropsWithoutRef } from 'react';

import { IconSystem } from '@/icons/IconSystem';
import { IconVariantMap } from '@/icons/icons-system';

import styles from './TextButton.module.scss';

type ButtonType = {
	display: 'highlight' | 'default';
};

type PropsWithIcon<IconName extends keyof IconVariantMap> = ComponentPropsWithoutRef<'button'> & {
	iconName: IconName;
	iconVariant: IconVariantMap[IconName];
} & ButtonType;

type PropsWithoutIcon = ComponentPropsWithoutRef<'button'> & ButtonType;

export function TextButton<IconName extends keyof IconVariantMap>(props: PropsWithoutIcon): JSX.Element;
export function TextButton<IconName extends keyof IconVariantMap>(props: PropsWithIcon<IconName>): JSX.Element;
export function TextButton<IconName extends keyof IconVariantMap>({
	className,
	children,
	display,
	...props
}: PropsWithIcon<IconName> | PropsWithoutIcon) {
	let icon = null;
	let rest: ComponentPropsWithoutRef<'button'>;
	if ('iconName' in props) {
		const { iconName, iconVariant, ...otherProps } = props;
		rest = otherProps;

		icon = <IconSystem icon={iconName} variant={iconVariant} />;
	} else {
		rest = props;
	}

	return (
		<button className={`${className ?? ''} ${styles.button} ${styles[display]}`} {...rest}>
			{icon}
			{children}
		</button>
	);
}
