import { ThemeSelector } from '../ThemeSelector/ThemeSelector';

import styles from './RedesignModalContent.module.scss';

type Props = {
	theme: string | undefined;
	onThemeChange?: (theme: string) => void;
};

export function RedesignModalContent({ theme, onThemeChange }: Props) {
	return (
		<div className={styles.content}>
			<h1>Frischer Look für BR24!</h1>
			<p>
				BR24 hat ein neues Aussehen bekommen. Sie können zwischen heller und dunkler Ansicht wählen. Gleich hier ausprobieren oder jederzeit
				ändern im Menü unter „Service“ oder am Ende der Seite im Footer.
			</p>
			<ThemeSelector theme={theme} onThemeChange={onThemeChange} />
		</div>
	);
}
