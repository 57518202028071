import { useDebounceCallback } from '@react-hook/debounce';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const scrollMap: Record<string, number> = {};

export function useMaintainScrollPosition() {
	// sessionStorage.setItem({})

	const router = useRouter();

	const updateScrollPosition = useCallback(() => {
		scrollMap[router.asPath] = window.scrollY;
	}, [router.asPath]);

	const debouncedUpdateScrollPosition = useDebounceCallback(updateScrollPosition, 100);

	useEffect(() => {
		const top = scrollMap[router.asPath] ?? 0;

		window.scrollTo({ top, behavior: 'auto' });
	}, [router.asPath]);

	useEffect(() => {
		window.addEventListener('scroll', debouncedUpdateScrollPosition);

		return () => {
			window.removeEventListener('scroll', debouncedUpdateScrollPosition);
		};
	});
}
